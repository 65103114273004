import React from 'react'
import Left_menu from './Left_menu'
import config from "../config"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import Swal from 'sweetalert2'

const Customer_header = () => {

  let navigate = useNavigate()
  let userDetail = localStorage.getItem('ship_rocket_user');
  console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  React.useEffect(()=>{
    get_wallet_balance()
    dimension_detail()
  },[])
  const get_wallet_balance=()=>{
    let dataToSend = {customer_id : userData.customer_id};
   
     console.log("datatoSend",dataToSend)
     let url = config.apiUrl + '/user/get_wallet_balance';
     axios.post(url, dataToSend, { headers: config.headers })
         .then((res) => {
           console.log("responseJson => ", res);
           if(res.data.status==true){
       setbalanceState({total_balance:res.data.find_customer_wallet})
    }
   else{
       const Toast = Swal.mixin({
           toast: true,
           position: 'bottom-end',
           showConfirmButton: false,
           timer: 3000
         })
         Toast.fire({
           background:"rgb(231, 91, 75)",
           type: 'Unsuccess',
           title: "Something Went Wrong !",
           color:"white"
         });
   } })
       
         .catch((error) => {
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"rgb(231, 91, 75)",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
             console.log(error);
         });    
   
   }
   const dimension_detail = () =>{
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  return (
    <div>
      <div  >
      {/* <?php include 'header_meta.php';?> */}
      <div class="row no-gutters vh-100 loader-screen hide_desk">
        <div class="col align-self-center text-white text-center">
            <h1><span class="font-weight-light">Shiprocket</span></h1>
            <div class="laoderhorizontal">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>

    <Left_menu/>
    <div class="wrapper">
        <div class="header hide_desk">
            <div class="row no-gutters">
                <div class="col-auto">
                    <button class="btn  btn-link text-dark menu-btn"><img src="img/menu.png" alt=""/><span class="new-notification"></span></button>
                </div>
                <div class="col text-center"><a href="/home"><img src="img/logo-header.png" alt="" class="header-logo"/></a></div>
                <div class="col-auto">
                    <a href="./" class="btn  btn-link text-dark"><i class="material-icons">home</i></a>
                    <a href="/my_wallet" class="btn  btn-link text-dark"><i class="material-icons">account_balance_wallet</i></a>
                </div>
            </div>
        </div>

        <div class="d-flex ">
            <div class="bg-white desk_menu-0 mb_display_none">
                {/* <?php include 'desk_left_menu.php';?> */}
            </div>
            <div class="w-100">
              <section class=" bg-white p-4 shadow-sm border-bottom mb_display_none">
                <div class="container">
                  <div class="row">
                  
                    <div class="col-12 col-md ">
                      <div class="card border-0 shadow-sm bg-light header-logo_2 ">
                        <div class="card-body">
                          <a href="/home"><img src="../../../img/icon_logo.png" alt="" class=""/></a>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3">
                      {/* <small>Welcome {userData.full_name!=null ? userData.full_name:""} </small> */}
                      {/* <h5>ABC Courier Company</h5> */}
                      <h5>Welcome {userData.full_name!=null ? userData.full_name:""}</h5>
                    </div>
                    <div class="col-12 col-md-4 text-end pt-2">
                      <small ><a type="button" class="btn btn-sm btn-outline-dark upgrade_btn" href='/'> 
                      <i class="material-icons-outlined">add</i>
                       Create Shipment
                       </a>
                       </small>
                     <small><a href="/my_wallet" class="btn btn-sm btn-outline-main upgrade_btn" style={{marginLeft:"10px"}}> <i class="material-icons-outlined">account_balance_wallet</i> Wallet Balance:{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{balancestate.total_balance.map((sub)=>(
                       parseFloat(sub.wallet_balance).toFixed(2)))}</a></small>
                    </div>
                    <div class="col-12 col-md-4 top_head_ma">
                      <ul class="navbar-nav justify-content-end">
                          <li class="nav-item">
                            <a class="nav-link" href="/home"><i class="material-icons-outlined">home</i> Home</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="/my_shipments"><i class="material-icons-outlined">view_in_ar</i> My Shipments</a>
                          </li> 
                          <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"> <i class="material-icons-outlined">account_circle</i> My Account</a>
                            <div class="dropdown-menu ">
                              <div class="top_nave_drop">
                                  <ul class="nav flex-column">
                                    <li class="nav-item">
                                      <a class="nav-link" href="/profile">My Profile</a>
                                    </li>
                                    <li class="nav-item">
                                      <a class="nav-link" href="/change_password">Change Password</a>
                                    </li>
                                    <li class="nav-item">
                                      <a class="nav-link" href="/">Rate Calculator</a>
                                    </li>
                                    <li class="nav-item">
                                      <a class="nav-link" href="/my_wallet">Wallet</a>
                                    </li>
                                    <li class="nav-item">
                                      <a class="nav-link " href="/settings">settings</a>
                                    </li>
                                  </ul>

                                  
                                  <hr/>
                                  <div class="text-center">
                                      <a href="">Logout</a>
                                  </div>
                              </div>
                            </div>
                          </li> 
                      </ul>
                    </div>
                    
                  </div>      
                </div>
              </section>
        </div>
        
    </div>
    </div>
    </div>
    </div>
  )
}

export default Customer_header