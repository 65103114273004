import React, { useState, useEffect } from 'react'
import Shipment_steps from './Shipment_steps'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import config from "../config"
import Header_nav from './Header_nav';
import axios from 'axios';

const Address_details = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
  console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  let navigate = useNavigate();
  const location = useLocation();
  // console.log("location==>122", location)
  let  parsed = queryString.parse(location.search);
  console.log("parsed",parsed);
  const [state, setState] = React.useState({ sender_name: "", sender_city: parsed.sender_city, sender_country_code: parsed.sender_country_code, sender_postal_code: parsed.sender_postal_code, sender_mobile: "", sender_address: "", sender_email: "", receiver_name: "", receiver_city: parsed.receiver_city, receiver_country_code:parsed.receiver_country_code, receiver_postal_code: parsed.receiver_postal_code, receiver_mobile: "", receiver_address: "", receiver_email: "", company_name: "", packaging_type: "", total_amount: "", 
  // weight: location.state.weight, height: location.state.height, width: location.state.width, length: location.state.length,
   insurance: "", sender_country_name: parsed.sender_country_name, receiver_country_name: parsed.receiver_country_name })
  console.log("state", state)
  const [returnstate, setReturnState] = React.useState({return_name:"",return_city: parsed.sender_city,return_country_code:parsed.sender_country_code,return_postal_code:parsed.sender_postal_code, return_mobile: "", return_address: "", return_email: "",return_state_name:""})
  console.log("returnstate",returnstate)
  const[checkboxstate,setcheckboxState]=React.useState(true)
  const[codlimit , setcodlimit]=useState({limit:0})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [loState, setLoState] = useState({ total_amount: 0 })
  const [insurancestate, setinsurance] = useState({ insurance: 0 })
  const [commissionstate, setcommissionState] = useState({ commission: 0 })
  const [rowstate,setRowState]=useState([])
  console.log("rowstate",rowstate)
  const [errorState, setErrorState] = useState({ sender_mobile: "",receiver_mobile:"" })
  console.log("insurance", insurancestate)
  const[paymentmode,setpaymentmode]= useState({mode:1})
  console.log("paymentmode",paymentmode)
  const [codvalue,setcodvalue] = useState({cod_amount:0 , err:{}})
  console.log("codvalue",codvalue)

  let dimensions = []
  if(Array.isArray(parsed.dimensions)){
     dimensions = parsed.dimensions
     dimensions = dimensions.map((sub)=>{
      
      console.log("dimensions------>",
      Object.assign({},sub.split(',') ))
      return  Object.assign({},sub.split(',') )
      
     })
    //  console.log("arr",arr)
  }else{
    dimensions.push(parsed.dimensions)
    dimensions = dimensions.map((sub)=>{
    
      console.log("dimensions------>",
      Object.assign({},sub.split(',') ))
      return  Object.assign({},sub.split(',') )
      
     })
  }
  console.log("dimensions",dimensions)
  let total_weight = 0
  dimensions.map((sub)=>{
    total_weight = Number(total_weight)+ Number(sub[0])
  })
  console.log("total_weight",total_weight)
  useEffect(() => {
    dimension_detail()
    carrierCommission()

    if(parsed.carrier_id=="1656377059"){
      check_cod_limit()
    }
  }, [])
      
  const check_cod_limit = ()=>{
    let full_api = config.apiUrl + `/shipment/check_limit_cod`;
    let sendData = {carrier_id:parsed.carrier_id , product_id:parsed.service_provider_id!=undefined && parsed.service_provider_id!=""?parsed.service_provider_id:""};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
     setcodlimit({...codlimit , limit:res.data.output})
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

// let carrier_commission = parseFloat((Number(parsed.total_amount)* Number(commissionstate.commission) / 100).toFixed(2))


  let amount = parseFloat((Number(parsed.total_amount) + Number(insurancestate.insurance))*18/100).toFixed(2)
  console.log("amount",amount)
       

let total_paid_amount = parseFloat(Number(parsed.total_amount) + Number(insurancestate.insurance) + Number(amount)  ).toFixed(2)

  const handleChange1 = (e) => {

    let names = e.target.name;
    let value = e.target.value;
      console.log(value," ---  ",names)
    if (names == "sender_mobile") {
      var re = new RegExp("^.{10}$");
      // console.log(value,"   regex number check =   ",re.test(value))
      setErrorState({ ...errorState, sender_mobile: "" })
      if (re.test(value)) {
        re = new RegExp("^[0-9]+$");
        if (!re.test(value)) {
          setErrorState({ ...errorState, sender_mobile: "please enter valid number" })
          //console.log("please enter valid number")
        }
      } else {
        setErrorState({ ...errorState, sender_mobile: "please enter 10 digit number " })
       // return(null)
        //console.log("please check length")
      }

      // let rea = re.test(value)
    }
    if (names == "receiver_mobile") {
      var re = new RegExp("^.{10}$");
      // console.log(value,"   regex number check =   ",re.test(value))
      setErrorState({ ...errorState, receiver_mobile: "" })
      if (re.test(value)) {
        re = new RegExp("^[0-9]+$");
        if (!re.test(value)) {
          setErrorState({ ...errorState, receiver_mobile: "please enter valid number" })
          //console.log("please enter valid number")
        }
      } else {
        setErrorState({ ...errorState, receiver_mobile: "please enter 10 digit number " })
       // return(null)
        //console.log("please check length")
      }

      // let rea = re.test(value)
    }
    setState({ ...state, [names]: value })
    if(checkboxstate==true){
    setReturnState({...returnstate,return_name:state.sender_name, return_mobile:state.sender_mobile, return_address:state.sender_address, return_email:state.sender_email , return_state_name:parsed.sender_state_name})
    }
  }

  React.useEffect(()=>{
    let items = [...rowstate]
     
     dimensions.map((sub)=>(
        items.push({total_value:"",shipment_content:"",quantity:""})
     ))
     console.log("items",items)
   setRowState(items)
  },[])
  const handleChange2 = (e, index) => {
    console.log("eee",e.target.value)

   let items = [...rowstate];
   let names = e.target.name;
   let value = e.target.value;
  //  if(value > 0) {
  //    items[index].error[names] = false;
  //  }else{
  //    items[index].error[names] = true;
  //  }
  // console.log(items[index][names])
   items[index][names] = value;
   setRowState(items)
   //setState({ ...state, [names]: value })
 }
   const next_step = () => {
   
    let flag = true
     if( paymentmode.mode==2){
      let itemsR = {...codvalue};
     
   
    
      if(codlimit.limit!=0){
        if (itemsR.cod_amount > codlimit.limit) {
          itemsR.err["cod_error"] = true;   
          flag=false
        }
        else{
          flag= true
        }
      }
      else{
        flag= true
      }
    }
    else{
      flag= true
    }
    console.log("codvalue" , codvalue)
    console.log("flag" , flag)

     if (userData.customer_id != undefined) { 
    if (state.sender_name != "" && state.sender_postal_code != "" && state.sender_mobile != ""&& state.sender_address != ""&& state.sender_email != ""&& state.receiver_name != ""&& state.receiver_city != ""&& state.receiver_postal_code != ""&& state.receiver_mobile != ""&& state.receiver_address != ""&& state.receiver_email != ""&& state.total_value != ""&& state.shipment_content != "" ) {
     
      if(returnstate.return_name!="" && returnstate.return_mobile!="" && returnstate.return_address!="" && returnstate.return_email!=""){
      if(errorState.sender_mobile == "" && errorState.receiver_mobile == ""){
         if(paymentmode.mode==1 || paymentmode.mode==2 && codvalue.cod_amount!="" ){
         if(flag){
      navigate({pathname:`/payment_details`,
       search: `sender_name=${state.sender_name}&sender_city=${state.sender_city}&sender_postal_code=${state.sender_postal_code}&sender_mobile=${state.sender_mobile}&sender_address=${state.sender_address}&sender_email=${state.sender_email}&receiver_name=${state.receiver_name}&receiver_city=${state.receiver_city}&receiver_postal_code=${state.receiver_postal_code}&receiver_mobile=${state.receiver_mobile}&receiver_address=${state.receiver_address}&receiver_email=${state.receiver_email}&return_name=${returnstate.return_name}&return_city=${returnstate.return_city}&return_state_name=${returnstate.return_state_name}&return_postal_code=${returnstate.return_postal_code}&return_mobile=${returnstate.return_mobile}&return_address=${returnstate.return_address}&return_email=${returnstate.return_email}${rowstate.map((sub)=>(`&items=${sub.total_value},${sub.shipment_content},${sub.quantity}`))}&company_name=${parsed.company_name}&courier_id=${parsed.courier_id}&carrier_id=${parsed.carrier_id}&packaging_type=${parsed.packaging_type}&serviceType=${parsed.serviceType}&total_amount=${parsed.total_amount}&sender_country_code=${parsed.sender_country_code}&receiver_country_code=${parsed.receiver_country_code}&sender_state_name=${parsed.sender_state_name}&receiver_state_name=${parsed.receiver_state_name}&product_type_name=${parsed.product_type_name}&shipment_type=${parsed.shipment_type}&insurance=${insurancestate.insurance}${dimensions.map((sub)=>(
        `&dimensions=${sub[0]},${sub[1]},${sub[2]},${sub[3]}`
        ))}&sender_country_name=${parsed.sender_country_name}&receiver_country_name=${parsed.receiver_country_name}&service_name=${parsed.service_provider}&cod_amount=${codvalue.cod_amount}&payment_mode=${paymentmode.mode==1?"prepaid":"cod"}` })
       }
       else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"rgb(231, 91, 75)",
          type: 'unsuccess',
          title: "Please fill COD value less than " + codlimit.limit,
          color:"white"
        });
       }
       
       }
       else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"rgb(231, 91, 75)",
          type: 'unsuccess',
          title: "Please enter cod value",
          color:"white"
        });
       }
    }
    else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        background:"rgb(231, 91, 75)",
        type: 'unsuccess',
        title: "Please enter valid Details",
        color:"white"
      });
    }
      }
      else{
        const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        
        Toast.fire({
          background:"rgb(231, 91, 75)",
          type: 'unsuccess',
          title: "Please fill all the Details",
          color:"white"
        });
        console.log("----error:   ");      
      
      } 
 
    }
    else{
      const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 3000
      })
      
      Toast.fire({
        background:"rgb(231, 91, 75)",
        type: 'unsuccess',
        title: "Please fill all the Details",
        color:"white"
      });
      console.log("----error:   ");      
    }
  }
  else{
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 2000
    })
    Toast.fire({
      background:"rgb(231, 91, 75)",
      type: 'unsuccess',
      title: "Please login first for further step",
      color:"white"
    });
  }
  
}
  const handleChange = (e) => {
    console.log("e ", e.target.name);
    console.log("e checked", e.target.checked);
    if (e.target.checked == true) {
      setinsurance({ ...insurancestate, insurance: 1 })
    }
    else {
      setinsurance({ ...insurancestate, insurance: 0 })
    }
  }

  const dimension_detail = () =>{
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const carrierCommission = () =>{
    let full_api = config.apiUrl + `/user/carrier_commission`;
    let sendData = {carrier_id:parsed.carrier_id};
  
    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      console.log("commission====>", res.data.commission);
      setcommissionState({commission:res.data.commission})

    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  const payment_mode =(e)=>{
    if(e.target.name=="prepaid"){
      setpaymentmode({mode:1})
    }
    else{
      setpaymentmode({mode:2})
    }
  }
  
  const codvalueChange=(e)=>{
      
    setcodvalue({...codvalue,cod_amount:e.target.value , err:{}})
  }


  const checkreturnbox=(e)=>{
    if(e.target.checked==true){
       setcheckboxState(true)
    }
    else{
      setcheckboxState(false)
      setReturnState({return_name:"",return_city:parsed.sender_city,return_country_code:parsed.sender_country_code,return_postal_code:parsed.sender_postal_code, return_mobile: "", return_address: "", return_email: ""})
    }
  }
  const handleChangereturn=(e)=>{
    setReturnState({...returnstate,[e.target.name]:e.target.value})
  }
  return (
    <div>
      {userDetail==null?
      <Header_nav/>:""}
       <div class="sub_header_inner">
</div>
        <section class="mb-3 mt-3">
  <div class="container">
    <Shipment_steps value={2}/>
    {/* <?php include 'shipment_steps.php';?> */}
    <div class="row">
      <div class="col-12 col-md">
        <section>
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="card border-0 shadow-sm mb-3 bg-light">
                <div class="card-body">
                  <div className='row'>
                  <div class="col-12 col-md">
                        <h4 class="pt-3 pb-3">Sender Address</h4>
                      </div>
                      <div class="col-12 col-md-4 text-end">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" onChange={(e) => checkreturnbox(e)} defaultChecked={checkboxstate}/>
                          <label class="form-check-label" for="inlineCheckbox1">Mark as return address</label>
                        </div>
                        </div>
                  </div>
                 
                  <div class="row align-items-end">
                      <div class="col-12 col-md">
                        <div class="row">
                          <div class="col-12 col-md-12 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="sender_name" onChange={(e) => handleChange1(e)} />
                              <label for="email">Full Name <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" style={{ backgroundColor: "#f2f2f2" }} id="email" placeholder="Enter email" name="sender_city" value={parsed.sender_city} readOnly />
                              <label for="email">City <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" style={{ backgroundColor: "#f2f2f2" }} id="email" placeholder="Enter email" name="sender_postal_code" value={parsed.sender_postal_code} readOnly />
                              <label for="email">Postal Code <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-12 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="sender_address" onChange={(e) => handleChange1(e)} />
                              <label for="email">Address <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="sender_mobile" onChange={(e) => handleChange1(e)} style={(errorState.sender_mobile != "") ? { borderColor: "red" } : {}} />
                              <label for="email">Mobile # <span class="text-danger">*</span></label>
                            </div>
                            {(errorState.sender_mobile != "") ?
                                  <label><span className="text-danger">{errorState.sender_mobile} *</span></label> : ""}
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                            <input type="email" className="form-control" id="email" placeholder="Enter email" name="sender_email" onChange={(e) => handleChange1(e)} />
                              <label for="email">Email <span class="text-danger">*</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="col-12 col-md-4 ">
                        <div class="d-grid mb-3">
                          <a href="" class="btn btn-outline-main btn-block">Enter Manually</a>
                        </div>
                        <div class="d-grid mb-3">
                          <button type="button" class="btn btn-main btn-block" data-bs-toggle="modal" data-bs-target="#addressbook">
                            Use Saved Address
                          </button>
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12">
              <div class="card border-0 shadow-sm mb-3 bg-light">
                <div class="card-body">
                  <h4 class="pt-3 pb-3">Receiver Address</h4>
                  <div class="row align-items-end">
                      <div class="col-12 col-md">
                        <div class="row">
                          <div class="col-12 col-md-12 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="receiver_name" onChange={(e) => handleChange1(e)} />
                              <label for="email">Full Name <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" id="email" style={{ backgroundColor: "#f2f2f2" }} placeholder="Enter email" name="receiver_city" value={parsed.receiver_city} readOnly />
                              <label for="email">City <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" style={{ backgroundColor: "#f2f2f2" }} name="receiver_postal_code" value={parsed.receiver_postal_code} readOnly />
                              <label for="email">Postal Code <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-12 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="receiver_address" onChange={(e) => handleChange1(e)} />
                              <label for="email">Address <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                            <input type="text" className="form-control" id="email" placeholder="Enter email" name="receiver_mobile" onChange={(e) => handleChange1(e)}  style={(errorState.receiver_mobile != "") ? { borderColor: "red" } : {}}/>
                              <label for="email">Mobile # <span class="text-danger">*</span></label>
                            </div>
                            {(errorState.receiver_mobile != "") ?
                                  <label><span className="text-danger">* {errorState.receiver_mobile}</span></label> : ""}
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                            <input type="email" className="form-control" id="email" placeholder="Enter email" name="receiver_email" onChange={(e) => handleChange1(e)} />
                              <label for="email">Email <span class="text-danger">*</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="col-12 col-md-4 ">
                        <div class="d-grid mb-3">
                          <a href="" class="btn btn-outline-main btn-block">Enter Manually</a>
                        </div>
                        <div class="d-grid mb-3">
                          <button type="button" class="btn btn-main btn-block" data-bs-toggle="modal" data-bs-target="#addressbook">
                            Use Saved Address
                          </button>
                        </div>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
            {checkboxstate==false?
            <div class="col-12 col-md-12">
              <div class="card border-0 shadow-sm mb-3 bg-light">
                <div class="card-body">
                  <h4 class="pt-3 pb-3">Return Address</h4>
                  <div class="row align-items-end">
                      <div class="col-12 col-md">
                        <div class="row">
                          <div class="col-12 col-md-12 mb-2">
                            <div class="form-floating">
                              <input type="text" class="form-control" id="email" placeholder="" name="return_name" onChange={(e) => handleChangereturn(e)}/>
                              <label for="email">Full Name <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                              <input type="text" class="form-control" id="email" placeholder="" name="return_city" value={state.sender_city} disabled/>
                              <label for="email">City <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                              <input type="text" class="form-control" id="email" placeholder="" name="return_city" value={parsed.sender_state_name} disabled/>
                              <label for="email">State<span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                              <input type="text" class="form-control" id="email" placeholder="" name="return_postal_code" value={state.sender_postal_code} disabled />
                              <label for="email">Postal Code <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-12 mb-2">
                            <div class="form-floating">
                              <input type="text" class="form-control" id="email" placeholder="" name="return_address" onChange={(e) => handleChangereturn(e)}/>
                              <label for="email">Address <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                              <input type="text" class="form-control" id="email" placeholder="" name="return_mobile" onChange={(e) => handleChangereturn(e)}/>
                              <label for="email">Mobile # <span class="text-danger">*</span></label>
                            </div>
                          </div>
                          <div class="col-12 col-md-6 mb-2">
                            <div class="form-floating">
                              <input type="text" class="form-control" id="email" placeholder="" name="return_email" onChange={(e) => handleChangereturn(e)}/>
                              <label for="email">Email <span class="text-danger">*</span></label>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            :""}
         
            <div class="col-12 col-md-12">
              <div class="card border-0 shadow-sm mb-3 bg-light">
                <div class="card-body">
                  <h4 class="pt-3 pb-3">Shipment Details</h4>
                  <div class="row ">
                      <div class="col-12 col-md">
                      {dimensions.map((sub,index)=>(
                        <section class="mb-3">
                          <h6>Item 1:</h6>
                          <div class="row">
                            <div class="col-12 col-md mb-2">
                              <div class="form-floating">
                                <input type="text" class="form-control" id="email" placeholder="Enter email" name="shipment_content" onChange={(e) => handleChange2(e,index)}/>
                                <label for="email">Shipment content (eg. mobile, laptop) <span class="text-danger">*</span></label>
                              </div>
                            </div>
                            <div class="col-12 col-md-2 mb-2">
                              <div class="form-floating">
                                <input type="number" class="form-control" id="email" placeholder="Enter email" name="quantity" onChange={(e) => handleChange2(e,index)}/>
                                <label for="email">Quantity <span class="text-danger">*</span></label>
                              </div>
                            </div>
                            <div class="col-12 col-md-3 mb-2">
                              <div class="form-floating">
                                <input type="text" class="form-control" id="email" placeholder="Enter email" name="total_value" onChange={(e) => handleChange2(e,index)}/>
                                <label for="number">Item Value<span class="text-danger">*</span></label>
                              </div>
                            </div>
                          </div>
                        </section>
                      ))}
                      </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-12">
              <div class="card border-0 shadow-sm mb-3 bg-light">
                <div class="card-body">
                  <h4 class="pt-3 pb-3">Shipment Mode</h4>
                  <div class="row">
                      <div class="col-12 col-md">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="prepaid" id="inlineRadio1" value="option1" onChange={(e) => payment_mode(e)} checked={paymentmode.mode==1} />
                          <label class="form-check-label" for="inlineRadio1">Prepaid</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="cod" id="inlineRadio2" value="option2" onChange={(e) => payment_mode(e)}  checked={paymentmode.mode==2} />
                          <label class="form-check-label" for="inlineRadio2">COD</label>
                        </div>
                        {paymentmode.mode==2?
                        <>
                        <div class="form-floating">
                                <input type="text" class="form-control" id="email" name="cod_amount" onChange={(e)=>codvalueChange(e)}/>
                                <label for="number">COD Value<span class="text-danger">*</span></label>
                               
                        </div>
                         {/* {codvalue.err.cod_error?
                          <>
                          <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                          lineHeight: "1.66667",
                          fontWeight: "400",
                          letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Cod Value less than {codlimit.limit}</small></>:""} */}
                        
                        </>   :""}
                           
                      </div>
                      <div class="col-12 col-md">
                        Insurance (Protect your shipment by adding insurance)
                            <div class="form-check form-switch  mb-2">
                               <input class="form-check-input"  type="checkbox" name="insurance" defaultChecked={insurancestate.insurance} onChange={(e) => handleChange(e)}/>
                               <label class="form-check-label" for="flexSwitchCheckChecked">Yes / No</label>
                            </div>
                      </div>
                  </div>



                  
                </div>
              </div>
            </div>
            
                 
            <div class="col-12 mt-3">
              <a onClick={() => { next_step() }} class="btn btn-main btn-lg">Next Step <span class="mt-5"><i class="material-icons-outlined">arrow_right_alt</i></span></a>
            </div>
           
          </div>
        </section>
      </div>
      <div class="col-md-4 bg-light mb_display_none">
        <section class="ps-4 pt-3">
          <h3 class="fs-5 pb-3">Basic information</h3>
          <div class="row">
            <div class="col-md-6">
              <small class="text-muted">Pickup</small>
              <h4 className="fs-6">{parsed.sender_postal_code} , {parsed.sender_city}</h4>
                    <p>{parsed.sender_country_name}</p>
            </div>
            <div class="col-md-6">
              <small class="text-muted">Delivery</small>
              <h4 className="fs-6">{parsed.receiver_postal_code} , {parsed.receiver_city}</h4>
                    <p>{parsed.receiver_country_name}</p>
            </div>
            <div class="col-md-12">
            <small className="text-muted">Item(s)</small>
                    <h4 className="fs-5">{dimensions.length}</h4>
            </div>
          </div>
        </section>

        <hr class="m-4"/>

        <section className="ps-4">
                <h3 className="fs-5 pb-3">Shipping Items</h3>
                <div className="row">
                  <div className="col-md-12">
                    {dimensions.map((sub)=>(
                    <p className="text-muted">{sub[0]} ({dimensionState.companyDetail.map((subscriber)=>( 
                      <>{subscriber.set_weight}</>
                     ))}) {sub[1]} x {sub[3]} x {sub[2]} ({dimensionState.companyDetail.map((subscriber)=>( 
                      <>{subscriber.set_dimension}</>
                     ))})</p>))}
                  </div>
                </div>
              </section>

        <hr class="m-4"/>


        <section className="ps-4">
                <h3 className="fs-5 pb-3">Order details</h3>
                <div className="row">
                  <div className="col-md-6">
                    <small className="text-muted">Mode</small>
                    <h4 className="fs-5">{parsed.company_id!="1656927880"?"Domestic":"International"}</h4>
                  </div>
                  <div className="col-md-6">
                    <small className="text-muted">Type</small>
                    <h4 className="fs-5">{parsed.shipment_type}</h4>
                  </div>
                  <div className="col-md-6">
                    <small className="text-muted">Service</small>
                    <h4 className="fs-5">{parsed.service_provider}</h4>
                  </div>
                  <div className="col-md-6">
                    <small className="text-muted">Total Weight</small>
                    <h4 className="fs-5">{total_weight} ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</h4>
                  </div>
                </div>
              </section>
        <hr class="m-4"/>

        <section className="ps-4">
                <h3 className="fs-5 pb-3">Pricing details</h3>
                <div className="row">
                  <div className="col-md-6">
                    <small className="text-muted">Base Price</small>
                  </div>
                  <div className="col-md-6">
                    <h4 className="fs-6">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{parsed.total_amount}</h4>
                  </div>
                  {/* <div className="col-md-6">
                    <small className="text-muted">Surcharge</small>
                  </div>
                  <div className="col-md-6">
                    <h4 className="fs-6">$0</h4>
                  </div> */}
                  <div className="col-md-6">
                    <small className="text-muted">Insurance</small>
                  </div>
                  <div className="col-md-6">
                    <h4 className="fs-6">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{insurancestate.insurance}</h4>
                  </div>
                 
                  <div className="col-md-6">
                    <small className="text-muted">Tax (VAT 18%)</small>
                  </div>
                  <div className="col-md-6">
                    <h4 className="fs-6">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{amount}</h4>
                  </div>
                 
                </div>
              </section>
        <hr class="m-4"/>
        <section class="ps-4">
          <div class="row pt-4">
            <div class="col-md-6">
              <h4 class="fs-4">Total Price</h4>
            </div>
            <div class="col-md-6">
              <h4 class="fs-4">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{total_paid_amount}</h4>
            </div>
          </div>
        </section>

      </div>
    </div>      
  </div>
</section>
    </div>
  )
}

export default Address_details