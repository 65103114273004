import React, { useState, createRef, useEffect } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../config"
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import queryString from 'query-string';
import Shipment_steps from './Shipment_steps'

const Package_list = () => {
    let navigate = useNavigate();
  const[loadingstate,setLoadingstate] = React.useState({loading:false})
  const[errorstate,seterrorstate] = React.useState({message:""})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const location = useLocation();
  // console.log("location==>",location.search)
  let  parsed = queryString.parse(location.search);
console.log("parsed",Array.isArray(parsed.dimensions));

let dimensions = []
if(Array.isArray(parsed.dimensions)){
   dimensions = parsed.dimensions
   dimensions = dimensions.map((sub)=>{
    
    console.log("dimensions------>",
    Object.assign({},sub.split(',') ))
    return  Object.assign({},sub.split(',') )
    
   })
  //  console.log("arr",arr)
}else{
  dimensions.push(parsed.dimensions)
  dimensions = dimensions.map((sub)=>{
    
    console.log("dimensions------>",
    Object.assign({},sub.split(',') ))
    return  Object.assign({},sub.split(',') )
    
   })
}
console.log("dimensions",dimensions)
  const [state, setState] = useState([]);
  let apiFlag = true;

  useEffect(() => {
    if(apiFlag){
      apiFlag = false;
    get_list()  
    dimension_detail()    
    }
}, [])

const dimension_detail = () =>{
  let full_api = config.apiUrl + `/user/get_company_info_api`;
  let sendData = {};

  axios.post(full_api, sendData, { headers: config.headers }).then(res => {
    setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
    console.log("rnmmmmm", res);
  }).catch((e) => {
    // toast.configure()
     //toast.error("Some thing went wrong")
    console.log("----error:   ", e);
  })
}

  const get_list = async (sortValue) => {
    setLoadingstate({...loadingstate,loading:true})
    
    let sort = "default";
    if(sortValue) {
      sort = sortValue
    }
    let dataToSend = {from_postal_code:parsed.origin_postal_code , from_country_code:parsed.origin_country_code , from_city_id:parsed.origin_city_id, to_postal_code:parsed.destination_postal_code,to_country_code:parsed.destination_country_code , weight:"",length:"",height:"",width:"",sort_by:sort
  };
  dataToSend.weight = dimensions.map((sub)=>(
    sub[0]
  ))
  dataToSend.length = dimensions.map((sub)=>(
    sub[2]
  ))
  dataToSend.height = dimensions.map((sub)=>(
    sub[1]
  ))
  dataToSend.width = dimensions.map((sub)=>(
    sub[3]
  ))
    console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/shipment_rate_time';
   
   // console.log("headers => ", config.headers);
   
    
    axios.post(url, dataToSend, { headers: config.headers })
        .then((responseJson) => {
          console.log("responseJson => ", responseJson.data);
          setLoadingstate({...loadingstate,loading:false})
          if(responseJson.data.status=="success"){
            setState(responseJson.data.output)
            if(responseJson.data.output.length==0){
              seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
            }
          }

          else{
          
          //  seterrorstate({...errorstate,message:"No services available for the selected route and package combination."})
          //  console.log("message",responseJson.data.message);
           Swal.fire({
            icon: 'error',
            // title: 'Oops...',
            text: 'Something Went wrong',
            
          })
          }
           
        })
        .catch((error) => {
            //Hide Loader
            setLoadingstate({...loadingstate,loading:false})
            console.log(error);
        });
};

const sort_by = (e) =>{
console.log(e.target.value);
get_list(e.target.value)
}
  return (
    <div>
      <section class="p-5">
  <div class="container">
  <Shipment_steps value={1}/>
    <div class="row p-5">
      <div class="col-12 col-md">
        <section class="mb-3">
            <div class="row">
              <div class="col-12 col-md-4 text-end">
                <a href="./" class="btn btn-outline-main">
                    <div class="d-flex justify-content-between">
                        <div class="">Edit your shipping preferences</div>
                        <div class="ps-2 "><i class="material-icons-outlined">sort</i></div>
                    </div>
                </a>
              </div>
              <div class="col-12 col-md"></div>
              <div class="col-12 col-md-3">
                <div class="form-floating">
                    <select class="form-select" id="sel1" name="sellist" onChange={(e)=>sort_by(e)}>
                    <option value="default">Default</option>
                        <option value="low">low-to-high</option>
                        <option value="high">high-to-low</option> 
                    </select>
                    <label for="sel1" class="form-label">Sort by...</label>
                </div>
              </div>
              
            </div>
        </section>
        <section class="package_list_box_simple">
        {loadingstate.loading?
          <div style={{width:"100%", display: "flex",
  justifyContent: "center"}}>

    
        <ReactLoading type={"bars"} color={"#f24827"} height={300} width={89} />
        </div>
        :""}
         <h3>
            {errorstate.message}</h3>
            {(state.length != 0) ?
          state.map((item,index)=>
            <div class="card border-0 shadow-sm ">
              <div class="card-body">
                <div class="row">
                    
                    <div class="col-12 col-md text-center mt-3">
                   <img src={`https://shiprocketcdn.fniix.com/images/${item.carrierImage}`}className="img-fluid" />
                    </div>
                    
                    <div class="col-12 col-md mt-3">
                      <h2>{item.service_name}</h2>
                      <p>{item.service_provider}</p>
                    </div>
                    <div class="col-12 col-md  mt-3 text-end">
                        <h1>{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{parseFloat(Number(item.total_charges)+Number(item.commission_charge)).toFixed(2)}</h1>
                        <small class="text-muted">+VAT</small>
                        <div class="d-grid">
                        <a  onClick={()=>navigate({
                        pathname:`/address_details`,
                        search:`origin_country_code=${parsed.origin_country_name}&sender_country_name=${parsed.origin_country_name}&receiver_country_name=${parsed.destination_country_name}&company_name=${item.service_name}&total_amount=${parseFloat(Number(item.total_charges)+Number(item.commission_charge)).toFixed(2)}&packaging_type=${item.packagingType!=undefined && item.packagingType!=""? item.packagingType:""}&sender_city=${parsed.origin_city}&sender_postal_code=${parsed.origin_postal_code}&receiver_city=${parsed.destination_city}&receiver_postal_code=${parsed.destination_postal_code}&sender_country_code=${parsed.origin_country_code}&receiver_country_code=${parsed.destination_country_code}&sender_state_name=${parsed.from_state_name}&receiver_state_name=${parsed.to_state_name}&carrier_id=${item.carrier_id}&courier_id=${item.courier_id!=undefined && item.courier_id!=""?item.courier_id:""}&serviceType=${item.serviceType!=undefined && item.serviceType!=""? item.serviceType:""}&service_provider=${item.service_provider}&product_type_name=${item.product_type_name!=undefined && item.product_type_name!=""?item.product_type_name:""}&shipment_type=${parsed.shipment_type}&service_provider_id=${item.service_provider_id!=undefined && item.service_provider_id!=""? item.service_provider_id:""}${dimensions.map((sub)=>(
                          `&dimensions=${sub[0]},${sub[1]},${sub[2]},${sub[3]}`
                          ))}`})} class="btn btn-main btn-block">Book Now</a>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            ):""}
        </section>
      </div>
      <div class="col-12 col-md-4">
        <div class="card bg-light border-0 shadow-sm">
          <div class="card-body">
            <h5 class="mb-3">Summary</h5>
            <strong>FROM</strong>
            <p>{parsed.origin_postal_code} - {parsed.origin_city} , {parsed.origin_country_name}</p>
            <strong>TO</strong>
            <p>{parsed.destination_postal_code} - {parsed.destination_city} , {parsed.destination_country_name}</p>

            <hr/>
            {dimensions.map((sub,index)=>(
              <>
            <strong>PARCEL ({index+1})</strong>
             <p className="text-muted">{sub[0]} ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))}) {sub[1]} x {sub[3]} x {sub[2]} ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_dimension}</>
                                 ))})</p>
            </>))}
          </div>
        </div>
      </div>
    </div>      
  </div>
</section>
    </div>
  )
}

export default Package_list
