import React from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Header_nav from './Header_nav';
import config from '../config';
import axios from 'axios';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import Footer from './Footer';
const Tracking = () => {
  let tracking_number = useParams()
  let userDetail = localStorage.getItem('ship_rocket_user');
  console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
 const [trackingstate,setTrackingState] = React.useState({track:{},isLoading:true})
 const [state, setState] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
 const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})



const dimension_detail = () =>{
  let full_api = config.apiUrl + `/user/get_company_info_api`;
  let sendData = {};

  axios.post(full_api, sendData, { headers: config.headers }).then(res => {
    setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
    console.log("rnmmmmm", res);
  }).catch((e) => {
    // toast.configure()
     //toast.error("Some thing went wrong")
    console.log("----error:   ", e);
  })
}

  React.useEffect(()=>{
    shipment_tracking()
  
    dimension_detail()
  },[])
    const shipment_tracking = () =>{
      
       let full_api = config.apiUrl + `/shipment/shipment_tracking`;
      let sendData = {customer_id:userData.customer_id , trackingNumber:tracking_number.tracking_no};
      console.log("sendData",sendData)
       
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
       if(res.data.status==200){
          setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
          console.log("res",trackingstate.track)
          setState({ ...state, shipmentDetail: res.data.ship_detail, isLoading:false})
        
       }
       else{
        setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
        console.log("res",trackingstate.track)
        setState({ ...state, shipmentDetail: res.data.ship_detail, isLoading:false})
       }
      
       
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }
    
   
  
  return (
    <div>
      {/* <Header_nav/> */}
      <div class="sub_header_inner">
</div>
  <section class="mb-3">
  {trackingstate.track!='' && state.shipmentDetail!='' ?
<div class="container">


{trackingstate.isLoading==false?



state.shipmentDetail.map((sub)=>(
<section class="bg-white p-5 mb-3">
<div class="row">
   <div class="col-12 col-md">
     <div class="text-muted">
         <p><a href="/"><i class="fa fa-angle-left" aria-hidden="true"></i> Back</a>
        <strong class="ms-2 me-2">AWB# {tracking_number.tracking_no}<span class=" fw-bold"></span></strong>
        
        {sub.sender.map((sub)=>(sub.city))} <i class="fa fa-long-arrow-right" aria-hidden="true"></i>   {sub.receiver.map((sub)=>(sub.city))}
        </p>
        {/* <p class=""><small class="text-muted">Location last updated on 3 May 2022 09:38</small></p> */}
      </div>
   </div>
   <div class="col-12 col-md-4 text-end"><h4><span class="badge bg-danger ms-2 me-2">{trackingstate.track.shipment_status}</span></h4></div>
</div>
<div class="row pt-5">
 <div class="col-md-8">
   
     

     {/* <section class="mt-3 mb-5 " >
       <h2></h2>
       <p>Delivery ETA - <span class="text-muted">03-02-2022 @ 23:29</span> | Scheduled Delivery - <span class="text-muted">03-02-2022 @ 23:29</span></p>
       <a class="btn btn-main btn-sm " style={{marginRight:"5px"}}>Copy share link</a>
       <a href="create_ticket.php" class="btn btn-outline-main btn-sm">Request Update</a>
       <a href="create_ticket.php" class="btn btn-outline-dark btn-sm"  style={{marginLeft:"5px"}}>Need Support?</a>
     </section> */}

    {trackingstate.track.history.length>0?
     <section class="track_box">
       
         <ul id="progress">
           {trackingstate.track.history.map((sub)=>(
             <>
             <li><div class="node green mb-2"></div><p>{sub.message} <small class="ms-3">{sub.location} <Moment format="MMM DD YYYY hh:mm:ss a">
                 {(sub.time)}
     </Moment> </small></p></li>
           {/* <li><div class="divider green"></div></li> */}
           </>
             ))}
         </ul>



     </section>
     :<div className='' style={{marginLeft:"50px"}}><b>Tracking History Not Found</b></div>}





     
 </div>
 <div class="col-md-4 bg-light">
   <section class="ps-4 pt-3">
     <h3 class="fs-5 pb-3">Basic information</h3>
     <div class="row">
       <div class="col-md-6">
         <small class="text-muted">Shipment Tracking #</small>
         <h4 class="fs-5">{sub.shipment_id}</h4>
       </div>
       <div class="col-md-6">
         <small class="text-muted">PO/Refernce #</small>
         <h4 class="fs-5">-----</h4>
       </div>
       <div class="col-md-6">
         <small class="text-muted">Pickup</small>
       {sub.sender.map((sub)=>(  <h4 class="fs-6">{sub.address+","+sub.city+" "+sub.country}</h4>))}
       </div>
       <div class="col-md-6">
         <small class="text-muted">Delivery</small>
         {sub.receiver.map((sub)=>( <h4 class="fs-6">{sub.address+","+sub.city+" "+sub.country}</h4>))}
       </div>
     </div>
   </section>

   <hr class="m-4"/>


   <section class="ps-4">
     <h3 class="fs-5 pb-3">Order details</h3>
     <div class="row">
       <div class="col-md-6">
         <small class="text-muted">Carrier</small>
         <h4 class="fs-5"><a href="">{sub.carrier_name}</a></h4>
       </div>
       <div class="col-md-6">
         <small class="text-muted">Order on</small>
         <h4 class="fs-5"><Moment format="MMM DD YYYY">
                 {new Date(sub.created*1000)}
     </Moment> 
                 <small class="text-muted">:<Moment format="hh:mm">
                 {new Date(sub.created*1000)}
     </Moment></small></h4>
       </div>
       <div class="col-md-6">
         <small class="text-muted">Total Price #</small>
         <h4 class="fs-5">{dimensionState.companyDetail.map((subscriber)=>( 
                           <>{subscriber.set_currency}</>
                          ))}{sub.total_amount}</h4>
       </div>
       <div class="col-md-6">
         <small class="text-muted">Total Weight</small>
         <h4 class="fs-5">{sub.total_weight!=undefined && sub.total_weight!=""?sub.total_weight:""} ({dimensionState.companyDetail.map((subscriber)=>( 
                           <>{subscriber.set_weight}</>
                          ))})</h4>
       </div>
     </div>
   </section>

 </div>
</div>

</section>  
))
:<ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />}    
</div>
:
<div className='row' style={{marginTop:"200px"}}>
<div className='col-4'></div>
<div className='col-4'>
 <h2  style={{marginLeft:"50px"}}>No Detail Found </h2>
 <small  style={{marginLeft:"70px"}}>Enter Valid Tracking Number</small><br/>
 <a   style={{marginLeft:"70px"}}href='/' className='btn btn-danger text-white'>Go Back to Home </a>
</div>
<div className='col-4'></div>

</div>}
  </section>
  <Footer/>
    </div>
   

    


  )
}

export default Tracking



