import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './views/home';
import Package_list from './views/Package_list';
import Address_details from './views/Address_details';
import Payment_details from './views/Payment_details';
import Shipment_created from './views/Shipment_created';
import Tracking from './views/Tracking';
import Index from './views/Index';
import Shipment_steps from './views/Shipment_steps';
import Customer_index from './views/Customer_index';
import My_Shipment from './views/My_Shipment';
import Print_invoice from './views/Print_invoice';
import Print_receipt from './views/Print_receipt';
import Shipment_detail from './views/Shipment_detail';
import Customer_profile from './views/Customer_profile';
import Edit_customer_profile from './views/Edit_customer_profile';
import My_wallet from './views/My_wallet';
import Checkout from './views/Checkout';
import Customer_settings from './views/Customer_settings';
import Change_password from './views/Change_password';
import Login from './views/Login';
import Register from './views/Register';
import Shipping_form from './views/Shipping_form';
import Demo_page from './views/demo_page';
import Get_quote from './views/Get_quote';

function App() {
  return (
   <Router>
    <Routes>
    <Route exact path='/' element={<Index />} />

    {/* <Route exact path='/' element={<Home />} /> */}
    <Route exact path='/package_list'element={<Package_list />} />
    <Route exact path='/address_details' element={<Address_details />} />
    <Route exact path='/payment_details' element={<Payment_details />} />
    <Route exact path='/shipment_created' element={<Shipment_created />} />
    <Route exact path='/tracking/:tracking_no' element={<Tracking/>} />
    <Route exact path='/shipment_steps' element={<Shipment_steps/>} />
    <Route exact path='/home' element={<Customer_index/>} />
    <Route exact path='/my_shipments' element={<My_Shipment/>} />
    <Route exact path='/print_invoice/:shipment_id' element={<Print_invoice/>} />
    <Route exact path='/print_receipt/:shipment_id' element={<Print_receipt/>} />
    <Route exact path='/shipment_detail/:shipment_id' element={<Shipment_detail/>} />
    <Route exact path='/profile' element={<Customer_profile/>} />
    <Route exact path='/edit_profile/:cust_id' element={<Edit_customer_profile/>} />
    <Route exact path='/my_wallet' element={<My_wallet/>} />
    <Route exact path='/checkout' element={<Checkout/>} />
    <Route exact path='/settings' element={<Customer_settings/>} />
    <Route exact path='/change_password' element={<Change_password/>} />
    <Route exact path='/login' element={<Login/>} />
    <Route exact path='/register' element={<Register/>} />
    <Route exact path='/shipping_form' element={<Shipping_form/>} />
   
    <Route exact path='/get_quote' element={<Get_quote/>} />
    <Route exact path='/demo' element={<Demo_page/>} />
    



    
  








    

    







    
    </Routes>
   </Router>
  );
}

export default App;
