let config = {




      apiUrl : "https://shiprocketbackend.fniix.com/v.1.0.1/web/query",
            //   apiUrl : "http://localhost:2024/v.1.0.1/web/query",




    headers:{
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        deviceid:"",
        devicemanu:"",
        usertuid:"0",
        loginstatus:"0",
        key:"E09F2280ghjghjg606C3BF43D882F479032F03B2C4172B795F997E03FA356604CA06A2C7090DBD6380454C39FD57BFCC6A24C712795021FB9501DBA54719285AFBC5AE5",
        token:""
    },
    
    UserId :null

}

export default config;