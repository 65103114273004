import React from 'react'
import {  useNavigate } from 'react-router-dom'
import Customer_header from './Customer_header'
import axios from "axios";
import Swal from 'sweetalert2';

import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import config from "../config"
import Header_nav from './Header_nav';
import Footer from './Footer';
const My_wallet = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    let navigate = useNavigate()
    const[state,setState] = React.useState({wallet_balance:""})
    const[balancestate, setbalanceState ]= React.useState({add_balance:[], total_balance:[]})
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})

   React.useEffect(()=>{
    get_wallet_balance()
    dimension_detail()
   },[])

    const get_wallet_balance=()=>{
     let dataToSend = {customer_id : userData.customer_id};
    
      console.log("datatoSend",dataToSend)
      let url = config.apiUrl + '/user/get_wallet_balance';
      axios.post(url, dataToSend, { headers: config.headers })
          .then((res) => {
            console.log("responseJson => ", res);
            if(res.data.status==true){
        setbalanceState({add_balance:res.data.added_wallet_transactions, total_balance:res.data.find_customer_wallet})
     }
    else{
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000
          })
          Toast.fire({
            background:"rgb(231, 91, 75)",
            type: 'Unsuccess',
            title: "Something Went Wrong !",
            color:"white"
          });
    } })
        
          .catch((error) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Something Went Wrong !",
                color:"white"
              });
              console.log(error);
          });    
    
    }

    const handleChange = (e) => {
        console.log("e ", e.target.name);
        console.log("e checked", e.target.value);
       setState({wallet_balance:e.target.value})
      }

      const addbalance=()=>{
         if(state.wallet_balance!=""){
        navigate({pathname:`/checkout`},{state:state.wallet_balance})
         }
         else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'Unsuccess',
                title: "Please Fill the Amount !",
                color:"white"
              });

         }
    }
    const dimension_detail = () =>{
        let full_api = config.apiUrl + `/user/get_company_info_api`;
        let sendData = {};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
      }
  return (
   <div>
    <Header_nav/>
    <div class="sub_header_inner">
</div>

<section class="py-4 bg-light">
    
<div class="container width_custom_80">
    <h4 class="mt-5 mb-3">Wallet</h4>
   
        
    <section>
<div class="card mb-3 border-0 shadow-sm">
    <div class="card-body">
        <div class="row">
            <div class="col">
                <p class="text-secondary small mb-0">Wallet Balance</p>
                {balancestate.total_balance.map((sub)=>(<h4 class="text-dark my-0">{dimensionState.companyDetail.map((subscriber)=>( 
                  <>{subscriber.set_currency}</>
                 ))}{parseFloat(sub.wallet_balance).toFixed(2)}</h4>))}
            </div>
            <div class="col-auto">
                <a href="my_wallet.php" class="btn btn-default button-rounded-36 shadow"  data-bs-toggle="collapse" data-bs-target="#add_money"><i class="material-icons" style={{color:"#dc291e"}}>add</i></a>
            </div>
                <div id="add_money" class="collapse mt-3">
                    <div class="input-group mb-3">
                        <input type="number" class="form-control" placeholder="Enter the value you want to add" name='wallet_balance' onChange={(e)=>handleChange(e)} value={state.wallet_balance}/>
                        <a onClick={()=>addbalance()} class="btn btn-main" type="submit">Add</a>
                      </div>
                </div>
               
        </div>
    </div>
</div>
</section>

<section>

    <div class="list-group list-group-flush my-3">
    {balancestate.add_balance.map((sub)=>(
        <a href="notification-details.html" class="list-group-item ">
            <div class="d-flex w-100 justify-content-between">
                {sub.payment_type==1?<h6 class="mb-2 text-dark">Point credited into the wallet</h6>:   <h6 class="mb-2 text-dark">Point debited from the wallet</h6>}
                {sub.payment_type==1? <p class="fs-5 text-success">+{dimensionState.companyDetail.map((subscriber)=>( 
                  <>{subscriber.set_currency}</>
                 ))}{sub.amount}</p>:<p class="fs-5 text-danger">-{dimensionState.companyDetail.map((subscriber)=>( 
                  <>{subscriber.set_currency}</>
                 ))}{sub.amount}</p>}
            </div>
            <p class="text-secondary mb-2">Mode:{sub.payment_method}</p>
            <small class="text-muted"><Moment format="MMM DD YYYY">
        {new Date(sub.created*1000)}
</Moment>  <Moment format="hh:mm:ss a ">
        {new Date(sub.created*1000)}
</Moment> </small>
        </a>
    ))}

      {/* {balancestate.debited_balance.map((sub)=>(
        <a href="notification-details.html" class="list-group-item ">
            <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-2 text-dark">Point debited from the wallet</h6>
                <p class="fs-5 text-danger">-{sub.amount}</p>
            </div>
            <p class="text-secondary mb-2">Mode: {sub.payment_method}</p>
            <small class="text-muted"><Moment format="MMM DD YYYY">
        {new Date(sub.created*1000)}
</Moment>  <Moment format="hh:mm:ss a ">
        {new Date(sub.created*1000)}
</Moment></small>
        </a>
      ))} */}
        
    </div>
</section>
            

</div>
        
        
</section>   
        <Footer/>
   </div>
   
  )
}

export default My_wallet


