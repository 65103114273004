import React from 'react'

import Footer from './Footer'
import Customer_header from './Customer_header'
import config from '../config' 

const Customer_index = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", userDetail.customer_id);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
  return (
     <div>    
        {userDetail!=null?
    <div >
         
        <Customer_header/> 
      
        <div style={{marginTop:"-680px"}} > 


        <div className='row'>
            <div className='col-5'></div>
            <div className='col-2 ' style={{marginTop:"50px"}}><a href="/" className='btn bg-danger text-white'>Go to Home</a></div>
            <div className='col-5'></div>

        </div>
        {/* <div class="container">
      
              <div class="row">
                <div class="col-12 col-md">
                  <div class="row text-center mt-4">
                      <div class="col-6 col-md-3">
                          <div class="card shadow-sm border-0 mb-4">
                              <div class="card-body">
                                  <i class="material-icons mb-4 md-36 text-template" style={{color:"#dc291e"}}>local_mall</i>
                                  <h2>2546</h2>
                                  <p class="text-secondary text-mute">Products</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-6 col-md-3">
                          <div class="card shadow-sm border-0 mb-4">
                              <div class="card-body">
                                  <i class="material-icons mb-4 md-36 text-template" style={{color:"#dc291e"}}>inbox</i>
                                  <h2>635</h2>
                                  <p class="text-secondary text-mute">Messages</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-6 col-md-3">
                          <div class="card shadow-sm border-0 mb-4">
                              <div class="card-body">
                                  <i class="material-icons mb-4 md-36 text-template" style={{color:"#dc291e"}}>local_florist</i>
                                  <h2>42</h2>
                                  <p class="text-secondary text-mute">Relevant RFQs</p>
                              </div>
                          </div>
                      </div>
                      <div class="col-6 col-md-3">
                          <div class="card shadow-sm border-0 mb-4">
                              <div class="card-body">
                                  <i class="material-icons mb-4 md-36 text-template" style={{color:"#dc291e"}}>store</i>
                                  <h2>154</h2>
                                  <p class="text-secondary text-mute">Catelog Views</p>
                              </div>
                          </div>
                      </div>
                  </div>
          
                  <section>
                      <div class="row">
                          <div class="col-12 col-md-6">
                              <div class="subtitle h6 mb-3">
                                  <div class="d-inline-block">
                                      This Year<br/>
                                      <p class="small text-mute">1/07/2019 - 28-07-2019</p>
                                  </div>
                              </div>
                              <div class="card mb-4 border-0 shadow-sm">
                                  <div class="card-body">
                                      <div class="row">
                                          <div class="col-auto">
                                              <span class="btn btn-default p-3" style={{backgroundColor:"#dc291e"}}>
                                                  <i class="material-icons">inbox</i>
                                              </span>
                                          </div>
                                          <div class="col pl-0">
                                              <p class="text-secondary mb-0">Total Messages</p>
                                              <h3 class="text-dark my-0">28</h3>
                                          </div>
                                      </div>
                                      <div class="row mt-4">
                                          <div class="col-12">
                                              <canvas id="canvas"></canvas>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-12 col-md-6">
                              <div class="subtitle h6 mb-3">
                                  <div class="d-inline-block">
                                      This Year<br/>
                                      <p class="small text-mute">1/07/2019 - 28-07-2019</p>
                                  </div>
                              </div>
                              <div class="card mb-4 border-0 shadow-sm">
                                  <div class="card-body">
                                      <div class="row">
                                          <div class="col-auto">
                                              <span class="btn btn-default p-3" style={{backgroundColor:"#dc291e"}}>
                                                  <i class="material-icons">store</i>
                                              </span>
                                          </div>
                                          <div class="col pl-0">
                                              <p class="text-secondary mb-0">Total Catelog Views</p>
                                              <h3 class="text-dark my-0">28</h3>
                                          </div>
                                      </div>
                                      <div class="row mt-4">
                                          <div class="col-12">
                                              <canvas id="canvas2"></canvas>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>


                  <section class="mb-3 mem_ber_info">
                    <div class="">
                        <h4>Grow your margins with a suite of tools built for B2B sales</h4>
                      <div class="row">
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card mb-4 border-0 shadow-sm">
                              <div class="card-body">
                                <div class="d-flex ">
                                    <div class="p-1"><i class="material-icons-outlined">store</i></div>
                                    <div class="p-1 "><h5>Set up storefront</h5></div>
                                </div>
                                <p>
                                    Showcase your brand and capabilities online - no design or coding required.
                                </p>
                                <a href="" class="btn btn-outline-main btn-sm">View details</a>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card mb-4 border-0 shadow-sm">
                              <div class="card-body">
                                <div class="d-flex ">
                                    <div class="p-1"><i class="material-icons-outlined">local_mall</i></div>
                                    <div class="p-1 "><h5>Post products</h5></div>
                                </div>
                                <p>
                                    Optimize your product listings for SEO based on site data, titles, keywords, and more.
                                </p>
                                <a href="" class="btn btn-outline-main btn-sm">View details</a>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card mb-4 border-0 shadow-sm">
                              <div class="card-body">
                                <div class="d-flex ">
                                    <div class="p-1"><i class="material-icons-outlined">leak_add</i></div>
                                    <div class="p-1 "><h5>Get traffic</h5></div>
                                </div>
                                <p>
                                    
                                    Increasing awareness and conversions with our marketing tools
                                </p>
                                <a href="" class="btn btn-outline-main btn-sm">View details</a>
                              </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-3">
                            <div class="card mb-4 border-0 shadow-sm">
                              <div class="card-body">
                                <div class="d-flex ">
                                    <div class="p-1"><i class="material-icons-outlined">track_changes</i></div>
                                    <div class="p-1 "><h5>Find new business</h5></div>
                                </div>
                                <p>
                                    Easily find and connect with buyers in the Request for Quotation (RFQ) market.
                                </p>
                                <a href="" class="btn btn-outline-main btn-sm">View details</a>
                              </div>
                            </div>
                        </div>
                      </div>      
                    </div>
                  </section>
                      
                  <section>
                      <div class="row">
                          <div class="col">
                              <div class="card mb-4 border-0 shadow-sm">
                                  <div class="card-body">
                                      <div class="row">
                                          <div class="col-12 col-md-7">
                                              <h3 class=" mt-3">Share Catalog on Social Media</h3>
                                              <p>Multiply the reach of your business and let more people know about it</p>
                                              <div class="alert alert-warning" role="alert">
                                                  Your Current catalog URL is : <a href="" target="_blank">https://www.b2bhulk.com/applecompany</a>
                                              </div>
                                              <h5>Want more visibility to attract buyers?</h5>
                                              <p>Share your catalog URL on social media</p>
                                              <div class="addthis_inline_share_toolbox_dalt" data-url="THE URL"></div>
          
                                          </div>
                                          <div class="col-12 col-md-5">
                                              <img src="img/share.webp" class="img-fluid" />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
          
                  <section>
                      <div class="row">
                          <div class="col">
                              <div class="card mb-4 border-0 shadow-sm">
                                  <div class="card-body">
                                      <div class="row">
                                          <div class="col-12 col-md-5">
                                              <img src="img/membership.webp" class="img-fluid" />
                                          </div>
                                          <div class="col-12 col-md-7">
                                              <h3 class=" mt-3">Explore our Paid Service and get more benefits</h3>
                                              <p>Multiply the reach of your business and let more people know about it</p>
                                              <ul>
                                                  <li>Higher visibility for your products/services</li>
                                                  <li>Get high ranking for your profile</li>
                                                  <li>Receive more buyer enquiries</li>
                                                  <li>Get more catelog views</li>
                                                  <li>SourcePro Active section</li>
                                                  <li>Lead alert on your email</li>
                                                  <li>Priority Support & much more..</li>
                                              </ul>
                                              <div class="text-end">
                                                  <button type="button" class="btn btn-primary">I am intersted!</button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </section>
          
                </div>
              </div> 
    


    
        
        </div>
         */}
        </div>
       
      
    </div>
    :
    <div className='row'>
        <div className='col-2'></div>
        <div className='col-8' style={{marginTop:"250px"}}>
            <h2 className='text-center'>NO DATA FOUND</h2>
        </div>
        <div className='col-2'></div>

    </div>
    }
    </div>
    
  )
}

export default Customer_index
