import React from 'react'
import { useNavigate } from 'react-router-dom'

const Shipment_steps = (props) => {
  let navigate = useNavigate()
    let value = props.value
  return (
    <div>
      <div class="row text-center">
      <div class="col-12 col-md">
        <div class="pb-5">
        <h3><a href="./"><img src="./img/logo/logo.png" class="img-fluid logo_in" /></a></h3>
        </div>
        <section class="mb-2">
            <ul id="progressbar" class="text-center">
           {value==2 || value ==3? <li onClick={()=>navigate(-1)} class={value==1 || value==2 ||value==3   ?"active":""}>Service Selection</li>:<li  class={value==1 || value==2 ||value==3   ?"active":""}>Service Selection</li>}

           {value==3?
            <li onClick={()=>navigate(-1)}  class={value==2 ||value==3?"active":""}>Address Details</li>: <li   class={value==2 ||value==3?"active":""}>Address Details</li>}
            <li  class={value==3?"active":""}>Payment Details</li>
            </ul>  
        </section>
      </div>
</div> 
    </div>
  )
}

export default Shipment_steps
