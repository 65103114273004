import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import config from "../config"
import axios from 'axios';
import Select from 'react-select';
import { useRef } from 'react';
import Footer from './Footer'
import Header_nav from './Header_nav'
import Shipping_form from './Shipping_form';
import { toast } from 'react-toastify';
import { getValue } from '@testing-library/user-event/dist/utils';
import Carousel from 'react-bootstrap/Carousel';
import Demo_page from './demo_page';
const Index = () => {

  let navigate = useNavigate()
  const[isLoadingstate,setisLoadingstate]= React.useState({isLoader:true});
  const[isLoadingstate2,setisLoadingstate2]= React.useState({isLoader:true});
  const[isLoadingstate3,setisLoadingstate3]= React.useState({isLoader:true});
  const[isLoadingstate4,setisLoadingstate4]= React.useState({isLoader:true});
  const[isLoadingstate5,setisLoadingstate5]= React.useState({isLoader:true});
  const[isLoadingstate6,setisLoadingstate6]= React.useState({isLoader:true});
  const[isLoadingstate7,setisLoadingstate7]= React.useState({isLoader:true});
  const[isLoadingstate8,setisLoadingstate8]= React.useState({isLoader:true});
  const[isLoadingstate9,setisLoadingstate9]= React.useState({isLoader:true});
  const [header, setHeader] = React.useState({title:"",sub_title:"",description:"",url:"",isLoading:true})
  const [icon, setIcon] = React.useState({title:"",description:"" , isLoading:true})
  const [state, setState] = React.useState({
    web_body_list: [],
  isLoading:true  
  })
  const [courierstate, setcourierState] = React.useState({
    courier_site: [],
  isLoading:true  
  })
  const [popularstate, setpopularState] = React.useState({
    popular_country: [],
  isLoading:true  
  })
  const [teststate, setTestState] = React.useState({
    web_testimonial_list: [],
    isLoading:true
  })
  
  React.useEffect(()=>{
    getWebData();
    // cat_subcat_detail()
    // getSubHeadData()
    getIconData()
    // getVideoData()
},[])
  const getWebData = () =>{
    let data = {};
    let url = config.apiUrl + '/home/web_detail_api';
     
    // console.log("headers => ", config.headers);
    
     
     
    axios.post(url, data, { headers: config.headers } ).then((res) => {
       console.log("response =>    ",res);
       if(res.data.status == "success") {
        let getData = res.data.web_data[0];
        
       
        setHeader({...header,title:getData.values[0].title,sub_title:getData.values[0].sub_title,description:getData.values[0].description,url:getData.values[0].url,isLoading:false})
        if(!res){
         setisLoadingstate({...isLoadingstate,isLoader:true})
         setisLoadingstate8({...isLoadingstate8,isLoader:true})           
        }
        else{
         
          setTimeout(() => setisLoadingstate({isLoader:false}), 1500)
          setTimeout(() => setisLoadingstate8({isLoader:false}), 5500)

        }
      }else  if(res.data.status == 401) {
        toast.configure();
        toast.error(res.data.message);
        localStorage.removeItem('ship_rocket_user');
        window.location.href = '/';

      }
       
      }).catch((e) => {
        // setisLoadingstate({...isLoadingstate,isLoader:false})
        console.log("e =>    ",e);
        toast.configure();
        toast.error("Some thing went wrong");
       
       });
}

const getIconData = () =>{
  let data = {};
  let url = config.apiUrl + '/home/web_detail_api';
  axios.post(url, data, { headers: config.headers }  ).then((res) => {
   
     
      let getData = res.data.web_data[2];
      
    
      setIcon({...icon,isLoading:false,title:getData.values[0].title,sub_title:getData.values[0].sub_title,description:getData.values[0].description,title_one:getData.values[1].title,sub_title_one:getData.values[1].sub_title,description_one:getData.values[1].description ,title_two:getData.values[2].title,sub_title_two:getData.values[2].sub_title,description_two:getData.values[2].description ,title_three:getData.values[3].title,sub_title_three:getData.values[3].sub_title,description_three:getData.values[3].description , title_four:getData.values[4].title,sub_title_four:getData.values[4].sub_title,description_four:getData.values[4].description, title_five:getData.values[5].title,sub_title_five:getData.values[5].sub_title,description_five:getData.values[5].description})
      if(!res){
        setisLoadingstate5({...isLoadingstate3,isLoader:true})
       }
       else{
       

        setTimeout(() => setisLoadingstate5({isLoader:false}), 4000)
      }
       
  })
}

const axios_get_api = () => {
  let url = config.apiUrl + '/home/web_body_list';
 
  let sendData = {
  web_body_list: {},
  dstatus:1
  };
  

  axios.post(url, sendData, { headers: config.headers }).then(res => {
    
    setState({ ...state,web_body_list: res.data.output ,isLoading:false });
  
    if(!res){
      setisLoadingstate4({...isLoadingstate6,isLoader:true})
      setisLoadingstate8({...isLoadingstate8,isLoader:true})

     }
     else{
     

      setTimeout(() => setisLoadingstate4({isLoader:false}), 3500)
      setTimeout(() => setisLoadingstate8({isLoader:false}), 5500)

    }
  })
}
React.useEffect(() => {
  axios_get_api()
}, [])


const axios_api = () => {
  
  let full_api =config.apiUrl + '/home/web_testimonial_list';
  let sendData = {
  web_testimonial_list: {},
  dstatus:1
  };
  

  axios.post(full_api, sendData, { headers:config.headers}).then(res => {
    setTestState({ ...teststate,web_testimonial_list: res.data.output ,isLoading:false });
 
    if(!res){
      setisLoadingstate6({...isLoadingstate7,isLoader:true})
      setisLoadingstate8({...isLoadingstate8,isLoader:true})

     }
     else{
    

      setTimeout(() => setisLoadingstate6({isLoader:false}), 4500)
      setTimeout(() => setisLoadingstate8({isLoader:false}), 5500)

    }
  })
}
React.useEffect(() => {
  axios_api()
}, [])

const courier_site_api = () => {
  
  let full_api =config.apiUrl + '/home/get_courier_site';
  let sendData = {
    courier_site: {},
  dstatus:1
  };
  

  axios.post(full_api, sendData, { headers:config.headers}).then(res => {
    setcourierState({ ...courierstate,courier_site: res.data.courier_site ,isLoading:false });
 
    
  })
}
React.useEffect(() => {
  courier_site_api()
}, [])
const popular_country = () => {
  
  let full_api =config.apiUrl + '/home/get_popular_country';
  let sendData = {
    popular_country: {},
 
  };
  

  axios.post(full_api, sendData, { headers:config.headers}).then(res => {
    setpopularState({ ...popularstate,popular_country: res.data.popular_country ,isLoading:false });
 
    
  })
}
React.useEffect(() => {
  popular_country()
}, [])

  const ship_form=(value)=>{
     console.log("value======",value)
     return ( 
     <Demo_page/>
     )
    //  navigate({pathname:`/shipping_form`},{state:{value} })
    }
  

    // React.useEffect(()=>{
    //   window.addEventListener("keydown", function(e) {
    //     if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
    //         e.preventDefault();
    //     }
    // }, false);
    // },[])
   
    
  return (
    <div>
        <Header_nav/>
      
        <section className='' style={{ marginTop: "-10rem",position: "relative",padding: "9rem 0px 194px 0px", backgroundRepeat: "no-repeat", backgroundSize: "cover",backgroundSize: "100%", background: `linear-gradient(256deg, #ef6d51e6, #ec54b2f2), url(${`https://shiprocketcdn.fniix.com/images/${header.url}`})`}}>
        
        <div class="shapesg">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
            </svg>
        </div>  

        
    
        <div class="container">
        
            <div class="row">
              <div class="col-12 col-md-5 text-white py-5">
                <h1 class="py-3">{header.title}</h1>
                <h2 class="py-3 mb_display_none">{header.sub_title}</h2>
                <p  class="py-3 mb_display_none ">
                 {header.description}
                </p>
              </div>
                <div class="col-12 col-md">
                <div class="card border-0 shadow-lg">
                    <Shipping_form/>
                  </div>
                </div>
            </div>
        

        
        </div>
  </section>


  <section class="partner_couriers">
    <div class="container">
      <div class="text-center p-5">
        <h2 class="py-3">The World's Largest Courier Comparison Site</h2> 
        <div class="row">
          {courierstate.courier_site.map((sub)=>
            <div class="col-6 col-md-2">
              <a href=""><img src={`https://shiprocketcdn.fniix.com/images/${sub.courier_img}`} class="img-fluid"  /></a>
            </div>
           )}
        </div> 
      </div>    
    </div>
  </section>

  <section class="p-5 text-center ">
    <div class="container">

      <div class="section-header py-3"> <span>Popular delivery destinations</span><h2>Popular delivery destinations</h2></div>


        <div class="row">
          {popularstate.popular_country.map((sub)=>(
          <div class="col-12 col-md-4 popul_cities" style={{background: `linear-gradient(273deg, rgba(111, 110, 110, 0.2), #2f2e2e), url(${`https://shiprocketcdn.fniix.com/images/${sub.image}`})`, backgroundRepeat: "no-repeat",
          backgroundSize: "cover"}} onClick={()=>navigate({pathname:'/get_quote'},{state:sub})} >
            <h2><a >{sub.country_name}</a></h2>
          </div>
        ))}
        </div>      
    </div>
  </section>



  <section class="">
    <div class="container text-center">

      <div class="section-header py-3"> <span>Why Choose Us</span><h2>Why Choose Us</h2></div>

        <div class="row">
            <div class="col-12 col-md-4 feature-home">
                <div class="icon color-1">
                    <i class="material-icons-outlined">{icon.title}</i>
                </div>
                <h4>{icon.sub_title} </h4>
                <p>{icon.description}</p>
            </div>
            <div class="col-12 col-md-4 feature-home">
                <div class="icon color-2">
                    <i class="material-icons-outlined">{icon.title_one}</i>
                </div>
                <h4>{icon.sub_title_one}</h4>
                <p>{icon.description_one}</p>
            </div>
            <div class="col-12 col-md-4 feature-home">
                <div class="icon color-3">
                    <i class="material-icons-outlined">{icon.title_two}</i>
                </div>
                <h4>{icon.sub_title_two}</h4>
                <p>{icon.description_two}</p>
            </div>
            <div class="col-12 col-md-4 feature-home">
                <div class="icon color-4">
                    <i class="material-icons-outlined">{icon.title_three}</i>
                </div>
                <h4>{icon.sub_title_three}</h4>
                <p>{icon.description_three}</p>
            </div>
            <div class="col-12 col-md-4 feature-home">
                <div class="icon color-5">
                    <i class="material-icons-outlined">{icon.title_four}</i>
                </div>
                <h4>{icon.sub_title_four}</h4>
                <p>{icon.description_four}</p>
            </div>
            <div class="col-12 col-md-4 feature-home">
                <div class="icon color-5">
                    <i class="material-icons-outlined">{icon.title_five}</i>
                </div>
                <h4>{icon.sub_title_five}</h4>
                <p>{icon.description_five}</p>
            </div>
        </div>   
    </div>
  </section>



  <section class="py-4 services">
    <div class="container">
      <div class="section-header py-3"> <span>Our Services</span><h2>Our Services</h2></div>
      
      <div class="row">
        {state.web_body_list.map((sub)=>(
        <div class="col-12 col-md-4 mb-3">
          <div class="card">
            <div class="card-body">
              <div class="card-img"> <img src={`https://shiprocketcdn.fniix.com/images/${sub.body_img}`} alt="" class="img-fluid"/></div>
              <div class="p-4">
                <h4><a href="">{sub.body_heading}</a></h4>
                <p>
                 {sub.body_content}
                </p>
              </div>
            </div>
          </div>
        </div>
        ))}
       
     
      </div>      
    </div>
  </section>


  <section class="parnter_us">
    <div class="container text-center">
      <h2>Do you have an online store?</h2>  
      <p>Optimise shipping at no extra cost with our PRO tool</p> 
      <a type="button" class="btn btn-main btn-lg">Try PRO Programme for free</a>
    </div>
  </section>



  <section class="reviews" >
    <div class="container text-center">
      {/* <!-- Carousel --> */}
        <div id="reviews" class="carousel slide" data-bs-ride="carousel">

          {/* <!-- Indicators/dots --> */}
      

          {/* <!-- The slideshow/carousel --> */}
          <div class="carousel-inner">
            
            <div class="carousel-item active">
              <div class="d-flex justify-content-center p-5-c">
                  <div class=" w-50-custom">
                    <div class="card border-0 shadow-lg" style={{backgroundColor:"#C0BAB9"}} >
                      
                      <div class="card-body">
                        
                      <Carousel>
                        {teststate.web_testimonial_list.map((sub)=>(
                        
                          <Carousel.Item >
                          <img src={`https://shiprocketcdn.fniix.com/images/${sub.testimonial_img}`} class="img-fluid" /> 
                         
                          {/* <Carousel.Caption style={{marginTop:"100px"}}> */}
                         <h4 class="pt-2">{sub.testimonial_title}</h4> 
                         {/* <h6>CEO, Walfare SKI</h6>  */}
                         <blockquote class="lead mb-5" > 
                           <i class="material-icons">format_quote</i> {sub.testimonial_description} 
                         </blockquote> 
                         {/* </Carousel.Caption> */}
                         </Carousel.Item>
                         
      ))}
    </Carousel>
                       
                      </div>
                    </div>
                  </div>
              </div>
            </div>
         
           
           
          </div>

        </div>
    </div>
  </section>
  <Footer/>
    </div>
  )
}

export default Index




