import React from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header_nav from './Header_nav'
import Shipping_form from './Shipping_form'

const Get_quote = () => {
    let location = useLocation()
    console.log("location",location)
    
  return (
    <div>
      <Header_nav/>
      <div class="sub_header_inner">
</div>
<div className='row'>
    <div className='col-2'></div>
    <div className='col-8 mt-4 mb-4'>
    <div class="card border-0 shadow-lg">
        {location.state!=undefined && location.state!=""?
                    <Shipping_form value={location.state}/>:
                    <Shipping_form/>}
                  </div>
    </div>
    <div className='col-2'></div>

</div>
     

      <Footer/>
    </div>
  )
}

export default Get_quote
