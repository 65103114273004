import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../config"
import Header_nav from "./Header_nav";
import ReactLoading from 'react-loading';
const Register = () => {
    let navigate = useNavigate();
    let userDetail = localStorage.getItem('ship_rocket_user');
    
    console.log("userDetail ===>   ",userDetail);
    const [state, setState] = useState({ email: "",password:"" });
    const [regsterState, setRegsterState] = useState({ email: "",password:"",full_name:"" });
    const [modalState, setModalState] = useState({ show: false });
    const [typeState, setTypeState] = useState({ type: "login" });
    const [userState, setUserState] = useState({ full_name: "" });
    const [styleState, setStyleState] = useState({ styles: 0 });
    const [trackState, setTrackState] = useState({tracking_no:""})
    const[trackresult,setTrackresult] = useState({})
    const[errorState,setErrorState] = useState()
    const[isLoading,setisLoading] = useState(false)
  
    
  
    
    
    useEffect(() => {
  
      if(userDetail!= null) {
        let uDetail = JSON.parse(userDetail)
        setUserState({full_name:uDetail.full_name})
        config.UserId = uDetail.customer_id
      }
    }, [])
    
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
    })
  
    const inputHandleChange = (e) => {
      const value = e.target.value;
      const text = e.target.name;
      setState({ ...state, [text]: value })
    }
    const inputHandleChangeR = (e) => {
      const value = e.target.value;
      const text = e.target.name;
      setRegsterState({ ...regsterState, [text]: value })
    }
    const inputHandletracking = (e) => {
      const value = e.target.value;
      const text = e.target.name;
      setTrackState({ ...trackState, [text]: value })
    }
  
    const loginButton = (z) => {
     
      console.log("state => ", state);
      if(state.email != "" && state.password != "") {
      let full_api = config.apiUrl + `/user/login_api`;
      let sendData = state;
      console.log("bb", sendData);
      axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
          console.log("res ", res);
          if (res.data.isValid) {
            
              localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
              setUserState({full_name:res.data.output.full_name})
              config.UserId = res.data.output.customer_id
              
              setModalState({ show: false })
               window.location.reload(false);
             // navigate('/')
          }else {
            Toast.fire({
              background:"rgb(231, 91, 75)",
              type: 'unsuccess',
              title: "Email or password incorrct",
              color:"white"
            });
             
          }
  
      }).catch((e) => {
  
  
      });
    }else {
      Toast.fire({
        background:"rgb(231, 91, 75)",
        type: 'unsuccess',
        title: "Please fill both fields",
        color:"white"
      });
    }
  }
  const regitserButton = () =>{
    console.log("regsterState", regsterState);
    if(regsterState.email != "" && regsterState.password != ""  && regsterState.full_name != "") {
      let full_api = config.apiUrl + `/user/register_api`;
      let sendData = regsterState;
      console.log("bb", sendData);
      axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
          console.log("res ", res);
          if (res.data.isValid) {
            
              localStorage.setItem('ship_rocket_user', JSON.stringify(res.data.output));
              setUserState({full_name:res.data.output.full_name})
              setModalState({ show: false })
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'success',
                title: res.data.message ,
                color:"white"
              });
               
              navigate('/')
          }else {
            Toast.fire({
              background:"rgb(231, 91, 75)",
              type: 'unsuccess',
              title: res.data.message ,
              color:"white"
            });
             
          }
  
      }).catch((e) => {
  
  
      });
    }else {
      Toast.fire({
        background:"rgb(231, 91, 75)",
        type: 'unsuccess',
        title: "Please fill all fields",
        color:"white"
      });
    }
  
  }
  const trackButton = () =>{
    setisLoading(true)
    
  
          setisLoading(false)
           navigate(`/tracking/`+ trackState.tracking_no)
    
  }
  const logOutButton = () =>{
    console.log("userDetail ===>   ");
    localStorage.removeItem('ship_rocket_user');
    userDetail = null;
    setUserState({full_name:""})
  
  }
  const changestyle=()=>{
    setStyleState({styles:1})
  }
  const changeloginstyle=()=>{
    setStyleState({styles:0})
  }
  const openregister=()=>{
    setModalState({show:true})
    setStyleState({styles:1})
  }
  const openlogin=()=>{
    setModalState({show:true})
    setStyleState({styles:0})
  }
  return (
    <div>
        <Header_nav/>
      <div class="sub_header_inner">
</div>
<section class="py-5 pyc-0 ">
  <div class="d-flex justify-content-center">
      <div class="p-2">
        <div class="card border-0 shadow-sm bg-light p-5 pc-0 ">
            <div class="card-body">
              <div class="text-center py-4">
                <h3>Registration</h3>
                <p>Please enter your deatils to register an account</p>
              </div>

              <div class="mb-3 mt-3">
                <label for="email" class="form-label">Name:</label>
                <input className="form-control" id="first_name" type="text" placeholder="Enter name" name="full_name" onChange={(e) => inputHandleChangeR(e)} required />
                        
              </div>
              <div class="mb-3 mt-3">
                <label for="email" class="form-label">Email:</label>
                <input className="form-control" id="first_name" type="email" placeholder="Enter email" name="email" onChange={(e) => inputHandleChangeR(e)} required  />
              </div>
              <div class="mb-3 mt-3">
                <label for="email" class="form-label">Password:</label>
                <input className="form-control" id="first_name" type="password" placeholder="Enter password" name="password" onChange={(e) => inputHandleChangeR(e)} required  />
              </div>
              {/* <div class="mb-3 mt-3">
                <label for="email" class="form-label">Confirm Password:</label>
                <input type="email" class="form-control" id="email" placeholder="Enter your password again" name="email"/>
              </div> */}
              <div class="d-grid pt-3">
                <a onClick={() =>
   regitserButton()
 } class="btn btn-main btn-lg btn-block">Sign Up</a>
              </div>
              <hr class="mt-5"/>
              <div class=" mt-3 text-center pt-3">
                Already have an account? <a href="/login">Login here</a>
              </div>

            </div>
          </div>
      </div>
  </div>
</section>
    </div>
  )
}

export default Register
